import { api } from './index.js';

const route = '/grid';

export const getGrid = async ({ project_uuid, model_uuid }) => {
	const { data } = await api.post(`${route}/get_grid`, {
		project_uuid,
		model_uuid,
	});
	return data;
};

export const getUnrotatedGrid = async projectId => {
	const { data } = await api.get(`/grid/${projectId}/unrotated_grid`);
	return data;
};
