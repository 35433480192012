import { Button, Spinner } from 'react-bootstrap';
import { Tooltip } from 'react-tooltip';
import styled from 'styled-components';

import Loader from '@components/loading/Loader';
import ProjectCard from '@components/projectComponents/ProjectCard';
import Alert from '@components/alert/Alert';

const NoProjectsMessages = styled.p`
	padding: 20px 0;
	text-align: center;
	font-size: 1.2rem;
`;

const ProjectsView = ({
	projectsData,
	totalProjects,
	projectDataQuery,
	isNewUser,
}) => {
	const {
		isLoading,
		isFetching,
		error,
		fetchNextPage,
		isFetchingNextPage,
		hasNextPage,
		refetch,
	} = projectDataQuery;

	return (
		<>
			{projectsData?.length === 0 && (
				<NoProjectsMessages>No projects found</NoProjectsMessages>
			)}

			<div>
				{isLoading && (
					<div className="col-md-12">
						<Loader />
					</div>
				)}

				{error && !isFetching && (
					<Alert heading={'An error occured:'} variant="danger">
						{error?.response?.data?.detail}
					</Alert>
				)}

				{!error && !isNewUser && (
					<>
						<div className="row">
							{projectsData.map(project => (
								<div
									className="col-12 col-sm-6 col-md-4 col-lg-3"
									key={`${project.uuid}`}>
									<ProjectCard
										project={project}
										refetchProjects={refetch}
									/>
								</div>
							))}
						</div>

						<div className="col-md-12 pb-3">
							{projectsData?.length < totalProjects && (
								<div className="text-center mb-5">
									<Button
										variant="success"
										className="mt-2 mb-2 col-6"
										disabled={
											!hasNextPage || isFetchingNextPage
										}
										onClick={fetchNextPage}>
										<span>Load more</span>{' '}
										{isFetchingNextPage && (
											<Spinner
												animation="border"
												variant="light"
												size="sm"
											/>
										)}
									</Button>
								</div>
							)}
						</div>
					</>
				)}
			</div>

			<Tooltip id="tooltip-projects-root" variant="light" />
		</>
	);
};

export default ProjectsView;
