import styled from 'styled-components';

import logo from '@assets/logo/logo_white.svg';

const Wrapper = styled.main`
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 50vh;
	> div {
		max-width: 1000px;
		padding: 1rem 1.5rem;
	}
`;

/*
 * This component is displayed when the site is in maintenance mode.
 * Maintenance mode is set in the .env file.
 */

const MaintenanceMode = () => {
	// Get the message from the .env file or use a default message
	const message =
		import.meta.env.VITE_APP_MAINTENANCE_MODE_MESSAGE ||
		'Our site is currently undergoing scheduled maintenance. Please check back later.';

	return (
		<Wrapper>
			<div>
				<img
					src={logo}
					width="150"
					className="mb-5"
					alt="Biodrone logo"
				/>
				<h1>We’ll be back soon</h1>
				<p>{message}</p>
				<p className="small text-muted">
					If you have any questions you can contact us at{' '}
					<a href="mailto:post@biodrone.no">post@biodrone.no</a>.
				</p>
			</div>
		</Wrapper>
	);
};

export default MaintenanceMode;
