import { Controller } from 'react-hook-form';
import { ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { BiShapePolygon } from 'react-icons/bi';
import { VscRecord } from 'react-icons/vsc';
import { ModelType } from '@contexts/Project.context';

const ModelTypeField = ({ control }) => {
	return (
		<Controller
			name="modelType"
			control={control}
			rules={{
				required: true,
			}}
			render={({ field }) => (
				<ToggleButtonGroup {...field} type="radio" className="d-flex">
					<ToggleButton
						id="model-type-object-detection"
						value={ModelType.OBJECT_DETECTION}
						variant="dark"
						className="w-50">
						<ToggleButtonLabel
							label="Object Detection"
							icon={<VscRecord aria-hidden />}
							helperText={
								<>
									<p className="mb-1">
										Create a model that can identify
										objects.
									</p>

									<p className="mb-0">
										For tasks like detecting and counting
										trees.
									</p>
								</>
							}
							required
						/>
					</ToggleButton>
					<ToggleButton
						id="model-type-object-segmentation"
						value={ModelType.SEGMENTATION}
						variant="dark"
						className="w-50">
						<ToggleButtonLabel
							label="Segmentation"
							icon={<BiShapePolygon aria-hidden />}
							helperText={
								<>
									<p className="mb-1">
										Create a model that can identify areas.
									</p>
									<p className="mb-0">
										For tasks like identifying areas of
										windfall.
									</p>
								</>
							}
							required
						/>
					</ToggleButton>
				</ToggleButtonGroup>
			)}
		/>
	);
};
export default ModelTypeField;

const ToggleButtonLabel = ({ label, icon, helperText }) => {
	return (
		<div className="pt-2">
			<div>
				{label} {icon}
			</div>
			<div className="small text-muted lh-sm my-2">{helperText}</div>
		</div>
	);
};
