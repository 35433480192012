import styled from 'styled-components';

const ProgressWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 6px;
`;

const BackgroundCircle = styled.circle`
	fill: none;
	stroke: rgba(255, 255, 255, 0.1);
`;
const ProgressCircle = styled.circle`
	fill: none;
	stroke: ${p => p.theme.colors.green};

	stroke-linecap: round;
	stroke-linejoin: round;

	stroke-dasharray: ${p => p.$dashArray};
	stroke-dashoffset: ${p => p.$dashOffset};
	transition: stroke-dashoffset 0.5s ease 0s;
`;
const CircularProgress = ({ size, strokeWidth, progress = 0, status }) => {
	// Size of the enclosing square
	//const size = this.props.size;
	// SVG centers the stroke width on the radius, subtract out so circle fits in square
	const radius = (size - strokeWidth) / 2;
	// Enclose cicle in a circumscribing square
	const viewBox = `0 0 ${size} ${size}`;
	// Arc length at 100% coverage is the circle circumference
	const dashArray = radius * Math.PI * 2;
	// Scale 100% coverage overlay with the actual percent
	const dashOffset = dashArray - (dashArray * progress) / 100;

	const isAborted =
		status === ('finished' && progress < 100) ||
		status === 'killed' ||
		status === 'error';

	if (isAborted) {
		return <p className="alert alert-danger p-1 text-center m-0">Error</p>;
	}

	const roundedProgress = Math.round(progress);

	return (
		<ProgressWrapper>
			<svg
				width={size}
				height={size}
				viewBox={viewBox}
				role="progressbar"
				aria-valuenow={progress}
				aria-valuemin="0"
				aria-valuemax="100"
				tabIndex="0">
				<BackgroundCircle
					cx={size / 2}
					cy={size / 2}
					r={radius}
					strokeWidth={`${strokeWidth}px`}
				/>

				<ProgressCircle
					cx={size / 2}
					cy={size / 2}
					r={radius}
					strokeWidth={`${strokeWidth}px`}
					// Start progress marker at 12 O'Clock
					transform={`rotate(-90 ${size / 2} ${size / 2})`}
					size={size}
					$dashOffset={dashOffset}
					$dashArray={dashArray}
				/>
			</svg>

			<div>
				<strong>{roundedProgress}%</strong>
			</div>
		</ProgressWrapper>
	);
};

export default CircularProgress;
