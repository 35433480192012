import { useState } from 'react';
import { NavLink, Link } from 'react-router-dom';
import styled from 'styled-components';

import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Alert from 'react-bootstrap/Alert';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';

import logo from '@assets/logo/logo_white.svg';
import Notifications from '@components/notifictions/Notifications';
import TrialBanner from './TrialBanner';
import OrganizationSwitch from '@components/organization/organizationSwitch';

import { useAuth } from '@contexts/User.context';
import ChatbotToggle from '@components/chatbot/ChatbotToggle';

const NavbarBrand = styled(Navbar.Brand)`
	margin-right: 1rem;
	@media (max-width: 991.98px) {
		margin-right: 0;
	}

	@media (max-width: 500px) {
		& > img {
			width: 25vw;
		}
	}
`;

const StyledNavbar = styled(Navbar)`
	background-color: ${props => props.theme.colors.background};
	box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
	z-index: 100;
	.container-fluid {
		z-index: 100;
		position: relative;
	}
	.nav-link {
		margin: 5px;
		font-weight: 500;
		letter-spacing: 1px;
	}
	.navbar-toggler {
		border: none;
	}
`;

const LoggedInNavbar = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const LoggedInMenu = styled.div`
	display: grid;
	grid-auto-flow: column;
	align-items: center;
	gap: 1.2rem;
	@media (min-width: 992px) {
		margin-left: auto;
	}
`;

const StyledNavLink = styled(NavLink)`
	color: ${props => props.theme.colors.textColor};
	font-weight: 500;
	letter-spacing: 1px;
	text-decoration: none;
	&.active {
		color: ${props => props.theme.colors.lightGreen};
	}
	&:hover {
		color: ${props => props.theme.colors.green};
	}
`;

const LogoutButton = styled(Button)`
	padding-inline: 1.5rem;
	width: 100%;
`;

const NavLinkWrapper = styled.div`
	display: none;
	@media (min-width: 991.98px) {
		display: flex;
		gap: 1rem;
	}
`;

const ButtonWrapper = styled.div`
	display: flex;
	gap: 1rem;
	@media (min-width: 991.98px) {
		border-left: 1px solid hsl(0, 0%, 30%);
		padding-left: 1rem;
	}

	@media (max-width: 500px) {
		gap: 0.5rem;
		padding-left: 0;
	}
`;

const OffcanvasHeader = styled(Offcanvas.Header)`
	border-bottom: 1px solid hsl(0, 0%, 30%);
`;

const OffcanvasBody = styled(Offcanvas.Body)`
	display: grid;
	gap: 1rem;
	align-content: start;
	h2 {
		color: hsl(0, 0%, 50%);
		font-size: 0.8rem;
		margin-bottom: 1rem;
	}
`;

const OffcanvasMenuList = styled.ul`
	list-style: none;
	padding: 0;
	margin: 0;
	display: grid;
	gap: 0.5rem;
`;

const Brand = () => (
	<NavbarBrand as={Link} to="/">
		<img
			src={logo}
			width="155"
			height="45"
			className="d-inline-block align-top"
			alt="Biodrone logo"
		/>
	</NavbarBrand>
);

const Navigation = () => {
	const { currentUser } = useAuth();

	return (
		<>
			<StyledNavbar fixed="top" variant="dark" expand={false}>
				<Container fluid>
					{currentUser?.loggedIn ? (
						<LoggedInNavigation />
					) : (
						<LoggedOutNavigation />
					)}
				</Container>
			</StyledNavbar>
		</>
	);
};

export default Navigation;

const LoggedInNavigation = () => {
	const {
		currentUser,
		logoutCurrentUser,
		roleAdmin,
		isOrgAdmin,
		tierTrial,
		tierPro,
		subscriptionActive,
	} = useAuth();

	const [showOffcanvas, setShowOffcanvas] = useState(false);
	const handleCloseOffcanvas = () => setShowOffcanvas(false);
	const handleShowOffcanvas = () => setShowOffcanvas(true);
	const handleLogout = () => {
		logoutCurrentUser();
		handleCloseOffcanvas();
	};

	const navLinkArray = [
		{
			to: '/',
			text: 'Home',
		},
		{
			to: '/projects',
			text: 'Projects',
		},
		// {
		// 	show: false,
		// 	to: '/models',
		// 	text: 'Models',
		// },
	];

	const userNavLinkArray = [
		{
			show: tierPro,
			to: '/subscription',
			text: 'Subscription',
		},
		{
			show: true,
			to: '/profile',
			text: 'Profile',
		},
	];

	const adminNavLinkArray = [
		{
			to: '/admin/organizations',
			text: 'Organizations',
		},
		{
			to: '/admin/users',
			text: 'Users',
		},
		{
			to: '/admin/projects',
			text: 'Projects',
		},
	];

	const orgAdminNavLinkArray = [
		{
			to: `organization/${currentUser?.active_org_id}/projects`,
			text: 'Organization Projects',
		},
		{
			to: `organization/${currentUser?.active_org_id}/profile`,
			text: 'Organization Profile',
		},
	];

	const NavLinkItem = ({ to, text }) => (
		<StyledNavLink to={to} onClick={handleCloseOffcanvas}>
			{text}
		</StyledNavLink>
	);

	const NavLinkListItem = ({ to, text }) => (
		<li>
			<NavLinkItem to={to} text={text} />
		</li>
	);

	return (
		<LoggedInNavbar>
			<Brand />

			<OrganizationSwitch />

			{tierTrial && <TrialBanner />}

			{
				// We need users to add their name to the profile because of Stripe
			}
			{!tierTrial && !currentUser?.name && (
				<Alert variant="warning" className="py-1 px-2 small my-0 ms-2">
					<Link className="inherit" to="/profile">
						Please update your profile
					</Link>
				</Alert>
			)}
			<LoggedInMenu>
				{tierPro && !subscriptionActive ? (
					<>
						<StyledNavLink to="/">Home</StyledNavLink>
						<StyledNavLink to="/profile">Profile</StyledNavLink>
						<LogoutButton
							onClick={handleLogout}
							variant="dark"
							size="sm">
							Log out
						</LogoutButton>
					</>
				) : (
					<>
						<NavLinkWrapper>
							{navLinkArray.map((navLink, index) => (
								<StyledNavLink
									key={`${navLink.to}-${index}`}
									to={navLink.to}>
									{navLink.text}
								</StyledNavLink>
							))}
						</NavLinkWrapper>

						<ButtonWrapper>
							<Notifications />
							<ChatbotToggle />
							<Navbar.Toggle
								as={Button}
								variant="dark"
								bsPrefix="navbar-toggler-custom"
								aria-controls="offcanvas-navbar"
								onClick={handleShowOffcanvas}>
								<span className="navbar-toggler-icon"></span>
							</Navbar.Toggle>
						</ButtonWrapper>

						<Navbar.Offcanvas
							show={showOffcanvas}
							onHide={handleCloseOffcanvas}
							placement="end"
							id="offcanvas-navbar">
							<OffcanvasHeader closeButton>
								<Offcanvas.Title>Navigation</Offcanvas.Title>
							</OffcanvasHeader>
							<OffcanvasBody>
								<div>
									<OffcanvasMenuList>
										{navLinkArray.map((navLink, index) => (
											<NavLinkListItem
												key={`${navLink.to}-${index}`}
												to={navLink.to}
												text={navLink.text}
											/>
										))}
									</OffcanvasMenuList>
								</div>

								{roleAdmin && (
									<>
										<hr className="my-1" />
										<div>
											<h2>Admin Menu</h2>
											<OffcanvasMenuList>
												{adminNavLinkArray.map(
													(navLink, index) => (
														<NavLinkListItem
															key={`${navLink.to}-${index}`}
															to={navLink.to}
															text={navLink.text}
														/>
													)
												)}
											</OffcanvasMenuList>
										</div>
									</>
								)}

								{!roleAdmin && isOrgAdmin && (
									<>
										<hr className="my-1" />
										<div>
											<h2>Organization</h2>

											<OffcanvasMenuList>
												{orgAdminNavLinkArray.map(
													(navLink, index) => (
														<NavLinkListItem
															key={`${navLink.to}-${index}`}
															to={navLink.to}
															text={navLink.text}
														/>
													)
												)}
											</OffcanvasMenuList>
										</div>
									</>
								)}

								<hr className="my-1" />
								<div>
									<h2>User</h2>
									<OffcanvasMenuList>
										{userNavLinkArray.map(
											(navLink, index) => {
												if (!navLink.show) return null;
												return (
													<NavLinkListItem
														key={`${navLink.to}-${index}`}
														to={navLink.to}
														text={navLink.text}
													/>
												);
											}
										)}

										<li className="mt-2">
											<LogoutButton
												onClick={logoutCurrentUser}
												variant="dark"
												size="sm">
												Log out
											</LogoutButton>
										</li>
									</OffcanvasMenuList>
								</div>
							</OffcanvasBody>
						</Navbar.Offcanvas>
					</>
				)}
			</LoggedInMenu>
		</LoggedInNavbar>
	);
};

const LoggedOutNavigation = () => (
	<>
		<Brand />
		<Navbar>
			<Nav>
				<ButtonWrapper>
					<ChatbotToggle />
					<Button variant="success" as={Link} to="/login">
						Log in
					</Button>
				</ButtonWrapper>
			</Nav>
		</Navbar>
	</>
);
