import styled from 'styled-components';
import { Controller } from 'react-hook-form';
import { ToggleButton, ToggleButtonGroup } from 'react-bootstrap';

const IconBar = styled.div`
	display: inline-block;
	width: 6px;
	height: ${props => props.$height}px;
	border: 2px solid ${props => props.theme.colors.green};
	background-color: rgba(0, 255, 0, 0.2);
	margin-left: 6px;
	opacity: 0.8;
`;

const StyledToggleButtonGroup = styled(ToggleButtonGroup)`
	&:focus {
		outline: 1px solid ${props => props.theme.colors.warning};
	}
`;

const objectSizes = {
	small: {
		label: 'Small',
		object_size: 1,
		icon: <IconBar $height="8" />,
		helperText: '~ 1m',
	},
	medium: {
		label: 'Medium',
		object_size: 5,
		icon: <IconBar $height="12" />,
		helperText: '~ 5m',
	},
	large: {
		label: 'Large',
		object_size: 25,
		icon: <IconBar $height="18" />,
		helperText: '~ 25m',
	},
};

const ObjectSizeSelect = ({ index, control, errors }) => {
	const hasError = errors?.classes?.[index]?.object_size;

	return (
		<div>
			<Controller
				name={`classes.${index}.object_size`}
				control={control}
				rules={{
					required: true,
				}}
				render={({ field }) => {
					return (
						<>
							<StyledToggleButtonGroup
								{...field}
								type="radio"
								className="d-flex"
								required>
								{Object.keys(objectSizes).map(size => {
									const info = objectSizes[size];
									return (
										<ToggleButton
											key={`${index}-${size}`}
											id={`object-size-${index}-${size}`}
											value={info.object_size}
											variant="dark">
											<ObjectSizeToggleLabel
												label={info.label}
												icon={info.icon}
												helperText={info.helperText}
											/>
										</ToggleButton>
									);
								})}
							</StyledToggleButtonGroup>
							{hasError && (
								<div className="invalid-feedback d-block">
									Size must me selected
								</div>
							)}
						</>
					);
				}}
			/>
		</div>
	);
};
export default ObjectSizeSelect;

const LabelWrapper = styled.div`
	display: flex;
	align-items: baseline;
	justify-content: center;
	height: 18px;
	margin-top: 4px;
`;
const ObjectSizeToggleLabel = ({ label, icon, helperText }) => {
	return (
		<div>
			<LabelWrapper>
				<div>{label}</div>
				{icon}
			</LabelWrapper>
			<small className="text-muted">{helperText}</small>
		</div>
	);
};
