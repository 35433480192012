import { api } from './index.js';

export const getAvailableModels = async () => {
	const { data } = await api.get('/models/available_models');
	return data;
};

export const getDemoModel = async model_uuid => {
	const { data } = await api.get(`/models/get_model/${model_uuid}`);
	return data;
};
