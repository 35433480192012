import { useMemo, useState } from 'react';
import styled from 'styled-components';
import { useFieldArray, Controller } from 'react-hook-form';
import { IoMdAddCircleOutline, IoMdRemoveCircleOutline } from 'react-icons/io';
import { Button } from 'react-bootstrap';

import { ModelType, useProject } from '@contexts/Project.context';

import Input from '@components/form/Input';
import ObjectSizeSelect from './ObjectSizeSelect';

const cleanClassLabel = text => {
	return text
		.replace(/[^a-zA-Z0-9 _-]/g, '') // Allow underscores and hyphens
		.trim()
		.replace(/\s+/g, '_') // Replace spaces with underscores
		.replace(/(?:^|\s)\S/g, letter => letter.toUpperCase()); // Capitalize the first letter of each word
};

const ListGroup = styled.ul`
	--bs-list-group-border-color: ${props => props.theme.colors.borderInput};
`;

const ObjectItemGrid = styled.div`
	display: grid;
	grid-template-columns: 1fr 1.5fr 30px;
	grid-gap: 10px;
	gap: 1rem;
	align-items: flex-start;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
`;

const IconButton = styled(Button)`
	background: none;
	border: none;
	font-size: 1.25rem;
	line-height: 1em;
	width: 1.2em;
	height: 1.2em;
	border-radius: 50%;
	margin-top: 1rem;
	text-align: center;
	padding: 0;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const ClassesFields = ({ control, errors, modelType }) => {
	const { fields, append, remove } = useFieldArray({
		control,
		name: 'classes',
	});

	const itemType =
		modelType === ModelType.OBJECT_DETECTION ? 'Objects' : 'Areas';

	return (
		<div>
			<p className="h6">{itemType} to identify</p>
			<p className="small text-muted lh-sm my-2">
				Add what kind of{' '}
				<span className="text-lowercase">{itemType}</span> and the
				typical approximate size.
			</p>
			<ListGroup className="list-group">
				{fields.map((item, index) => (
					<ListItem
						key={item.id}
						control={control}
						remove={remove}
						errors={errors}
						index={index}
					/>
				))}
			</ListGroup>

			<div className="d-flex justify-content-center mt-3">
				<Button
					className="px-4"
					type="button"
					variant="dark"
					onClick={() => append({ label: '', object_size: '' })}>
					<span>Add row</span> <IoMdAddCircleOutline />
				</Button>
			</div>
		</div>
	);
};

export default ClassesFields;

const ListItem = ({ control, errors, remove, index }) => {
	const { tasks } = useProject();
	// Memoize the computation of existingTasks
	const existingTasks = useMemo(() => {
		return tasks
			.map(task => task.classes.map(cls => cls))
			.reduce((acc, val) => acc.concat(val), []);
	}, [tasks]);

	const [hasMatchingExisting, setHasMatchingExisting] = useState(null);

	return (
		<li className="list-group-item">
			<ObjectItemGrid>
				<Controller
					render={({ field }) => (
						<Input
							{...field}
							label="Label"
							id={`classes-${index}-label`}
							onChange={e => {
								const cleanedValue = cleanClassLabel(
									e.target.value
								);

								const matchingTask = existingTasks.find(
									task =>
										task.class.toLowerCase() ===
										cleanedValue.toLowerCase()
								);

								if (matchingTask) {
									setHasMatchingExisting(matchingTask);
								} else if (hasMatchingExisting) {
									setHasMatchingExisting(null);
								}

								return field.onChange(cleanedValue);
							}}
							noWrap
							required
						/>
					)}
					rules={{
						required: true,
					}}
					name={`classes.${index}.label`}
					control={control}
				/>

				<ObjectSizeSelect
					index={index}
					control={control}
					errors={errors}
				/>

				<div>
					{index > 0 && (
						<IconButton
							type="button"
							onClick={() => remove(index)}
							variant="danger"
							title="Remove">
							<IoMdRemoveCircleOutline />
						</IconButton>
					)}
				</div>
			</ObjectItemGrid>
			{hasMatchingExisting && (
				<div className="alert alert-warning px-2 py-1 mt-1 small">
					<p className="mb-1">{`Label "${hasMatchingExisting.class}" already exists in model "${hasMatchingExisting.description}"`}</p>
					<p className="mb-0">
						To avoid confusion, we recomend you choose a label that
						clearly matches what you want to identify.
					</p>
				</div>
			)}
		</li>
	);
};
