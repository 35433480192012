import { api } from './index.js';

export const getTasks = async project_uuid => {
	const { data } = await api.get(`/tasks/tasks_with_model/${project_uuid}`);
	return data;
};

export const newTask = async ({
	project_uuid,
	classes,
	description,
	hover_description,
	segmentation,
	image_mode,
}) => {
	const postData = {
		project_uuid,
		classes,
		description,
		hover_description,
		segmentation,
		image_mode,
	};

	const { data } = await api.post(`/tasks/new_task`, postData);
	return data;
};

export const deleteModel = async model_uuid => {
	const { data } = await api.delete(`/models/${model_uuid}`);
	return data;
};

export const deleteMultiModel = async model => {
	const { data } = await api.delete(`/models/multimodel/${model.model_uuid}`);
	return data;
};
