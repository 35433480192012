import { useState } from 'react';
import styled from 'styled-components';

import { humanFileSize } from '@utils/files';

const FileItem = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0.5rem 1rem;
	border-bottom: 1px solid #414141;
	background-color: ${p =>
		p.$remove ? 'rgba(230, 57, 70, 0.5)' : 'transparent'};
	transition: all 0.2s ease-out;

	&:last-child {
		border-bottom: none;
	}
	button {
		margin-left: 1rem;
		appearance: none;
		background-color: transparent;
		border: 0;
		color: #fff;
		width: 1.5em;
		height: 1.5em;
		border-radius: 50%;
		display: inline-flex;
		justify-content: center;
		align-items: center;
		transition: all 0.2s ease-out;
		&:hover {
			background-color: rgba(230, 57, 70, 0.5);
			cursor: pointer;
		}
		&:disabled {
			opacity: 0.5;
			cursor: not-allowed;
		}
	}
`;
/**
 * Component for displaying a file in the file list
 *
 * @param {*} file - file object
 * @param {number} index - index of the file in the list
 * @param {function} removeFile - function for removing the file from the list
 * @param {boolean} submitting - whether the form is submitting
 */
const FileItemComponent = ({ file, index, removeFile, submitting }) => {
	const [remove, setRemove] = useState(false);

	// Remove the file from the list
	const handleRemoveFile = () => {
		setRemove(true);

		// Wait for the animation to finish before removing the file
		setTimeout(() => {
			removeFile(file);
		}, 300);
	};

	return (
		<FileItem $remove={remove}>
			<span>{`${index + 1}. ${file.name}`}</span>
			<div>
				<span>{humanFileSize(file.size)}</span>
				<button
					type="button"
					onClick={() => handleRemoveFile(file)}
					title="Remove this file"
					disabled={submitting}>
					<span>x</span>
				</button>
			</div>
		</FileItem>
	);
};
export default FileItemComponent;
