import { Controller } from 'react-hook-form';
import Input from '@components/form/Input';

const ControlledInput = ({
	control,
	name,
	label,
	controllerProps,
	...props
}) => {
	return (
		<Controller
			name={name}
			control={control}
			{...controllerProps}
			render={({ field }) => (
				<Input {...field} label={label} {...props} />
			)}
		/>
	);
};
export default ControlledInput;
