import { useEffect } from 'react';
import styled from 'styled-components';
import { Fill, Stroke, Style } from 'ol/style';
import { Draw, Select } from 'ol/interaction';

import { useProject } from '@contexts/Project.context';
import HexagonAccordion from './sidebarElements/HexagonAccordion';
import {
	Header,
	HeaderText,
	Container,
	CloseIcon,
} from './sidebarElements/SidebarTemplate';

const Content = styled.div`
	background-color: ${props => props.theme.colors.background};
`;

const HexagonInfo = () => {
	const { selectedHexagon, selectedSingleImage, dispatch } = useProject();

	const closeHexagonInfo = () => {
		selectedHexagon.selectInteraction?.getFeatures().clear();
		dispatch({ type: 'setSelectedHexagon', payload: null });
	};

	const handleKeyUp = e => {
		if (
			selectedHexagon?.selectInteraction?.getFeatures() &&
			e.key === 'Escape'
		)
			closeHexagonInfo();
	};

	useEffect(() => {
		window.addEventListener('keyup', handleKeyUp);
		return () => {
			window.removeEventListener('keyup', handleKeyUp);
		};
	}, [selectedHexagon]);

	if (!selectedHexagon || selectedSingleImage) return null;

	return (
		<Container $align="right" $zindex="20">
			<Content>
				<Header>
					<HeaderText>Selected Hexagon Info</HeaderText>
					<CloseIcon onClick={closeHexagonInfo} role="button" />
				</Header>
				<HexagonAccordion hexagonData={selectedHexagon.data} />
			</Content>
		</Container>
	);
};

export default HexagonInfo;

/**
 * Checks if the given event has a Draw interaction.
 *
 * @param {Event} event - The event object.
 * @returns {boolean} - True if the event has a Draw interaction, false otherwise.
 */
const hasDrawInteraction = event => {
	const currentInteractions = event.map.getInteractions().getArray();
	return currentInteractions.some(interaction => interaction instanceof Draw);
};

/**
 * Retrieves the unique feature types from the given array of features.
 *
 * @param {Array} featuresAtPixel The array of features to extract feature types from.
 * @returns {Array} An array containing the unique feature types.
 */
const getFeatureTypes = featuresAtPixel => {
	return featuresAtPixel.reduce((accumulator, feature) => {
		const featureType = feature.get('featureType');
		if (featureType)
			if (!accumulator.includes(featureType))
				accumulator.push(featureType);
		return accumulator;
	}, []);
};

export function addHexagonInteraction(
	layer,
	mapObject,
	dispatch,
	hexagonInteractionAdded
) {
	/**
	 * Determines if a hexagon can be selected.
	 *
	 * @param {Object} event The event object containing information about the pointer event.
	 * @returns {boolean} True if the hexagon can be selected, false otherwise.
	 */
	const selectHexagonCondition = event => {
		if (event.type !== 'singleclick' || hasDrawInteraction(event))
			return false;

		// If the zoom level is less than 18, allow selection
		const zoomLevel = mapObject.getView().getZoom();
		if (zoomLevel < 18) return true;

		const featuresAtPixel = mapObject.getFeaturesAtPixel(event.pixel);
		if (featuresAtPixel?.length < 1) return false;

		const disallowedFeatureTypes = ['detection', 'measurement'];

		const featureTypesAtPixel = getFeatureTypes(featuresAtPixel);

		const hasDisallowedFeatureId = featureTypesAtPixel.some(featureType =>
			disallowedFeatureTypes.includes(featureType)
		);
		if (hasDisallowedFeatureId) return false;

		return true;
	};

	const hexagonSelectStyle = new Style({
		zIndex: 1000,
		stroke: new Stroke({
			color: 'rgba(255, 140, 0, 1)',
			width: 4,
		}),
		fill: new Fill({
			color: 'rgba(255, 140, 0, 0.25)',
		}),
	});

	hexagonInteractionAdded.current = new Select({
		toggleCondition: () => false, // stops shift-hold from multi-selecting
		condition: selectHexagonCondition, // selects hexagon on singleclick if not clicking on disallowed features
		style: hexagonSelectStyle,
		layers: [layer],
	});

	hexagonInteractionAdded.current.on('select', function (e) {
		const { data } = e.selected[0]?.getProperties() ?? { data: null };
		if (data) {
			dispatch({
				type: 'setSelectedHexagon',
				payload: {
					data,
					selectInteraction: hexagonInteractionAdded.current,
				},
			});
		} else {
			dispatch({ type: 'setSelectedHexagon', payload: null });
		}
	});

	mapObject.addInteraction(hexagonInteractionAdded.current);

	layer.on('change:visible', () => {
		dispatch({ type: 'setSelectedHexagon', payload: null });
	});
}
