import styled from 'styled-components';
import Alert from '@components/alert/Alert';

import { useAuth } from '@contexts/User.context';

const StyledAlert = styled(Alert)`
	width: 100%;
	position: absolute;
	top: 64px;
	left: 0;
	z-index: 100;

	&.alert-dismissible .btn-close {
		padding: 0.75rem;
	}
`;

const sessionStorageKey = 'maintenanceBannerClosed';

/**
 * Display a banner alerting users about scheduled maintenance
 */
const MaintenanceAlert = () => {
	const { currentUser } = useAuth();

	// If the user is not logged in, don't show the alert
	if (!currentUser?.loggedIn) return null;

	const message = import.meta.env.VITE_APP_SCHEDULING_MAINTENANCE_MESSAGE; // Get the message from the .env file
	const hasClosed = sessionStorage.getItem(sessionStorageKey); // Check if the user has closed the alert

	// If there is no message or the user has closed the alert, don't show it
	if (!message || hasClosed) return null;

	return <AlertMessage message={message} />;
};

export default MaintenanceAlert;

const AlertMessage = ({ message }) => {
	const onClose = () => {
		sessionStorage.setItem(sessionStorageKey, 'true');
	};

	return (
		<StyledAlert type="info" className="py-2" onClose={onClose} dismissible>
			<p className="small">{message}</p>
		</StyledAlert>
	);
};
