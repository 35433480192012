import { useEffect, useRef } from 'react';
import styled, { keyframes } from 'styled-components';
import { Map, View } from 'ol/index';
import { defaults as defaultInteractions } from 'ol/interaction';
import { Tile as TileLayer } from 'ol/layer';
import { OSM } from 'ol/source';

import { useProject } from '@contexts/Project.context';
import { createTooltip } from '@utils/map/tooltip.overlay';

const spinner = keyframes`
	to {
		transform: rotate(360deg);
	}
`;

export const StyledMapView = styled.div`
	position: absolute;
	bottom: 38px;
	right: 0;
	width: 100%;
	height: calc(${props => props.theme.utils.fullHeight} - 36px);
	background-color: #000;
	.ol-zoom {
		top: auto;
		left: auto;
		right: 10px;
		bottom: 30px;
	}
	.ol-control button {
		background-color: ${props => props.theme.colors.background};
		color: ${props => props.theme.colors.textColor};
		&:hover {
			color: ${props => props.theme.colors.lightGreen};
		}
		&:active {
			color: ${props => props.theme.colors.lightGreen};
		}
	}
	.ol-tooltip {
		position: relative;
		background: rgba(0, 0, 0, 0.7);
		border-radius: 4px;
		color: white;
		padding: 4px 8px;
		white-space: nowrap;
		font-size: 18px;
		cursor: default;
		user-select: none;

		&:before {
			border-top: 6px solid rgba(0, 0, 0, 0.5);
			border-right: 6px solid transparent;
			border-left: 6px solid transparent;
			content: '';
			position: absolute;
			bottom: -6px;
			margin-left: -7px;
			left: 50%;
		}
	}
	&.spinner {
		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: rgba(0, 0, 0, 0.7);
			z-index: 1;
		}
		&:after {
			content: '';
			box-sizing: border-box;
			position: absolute;
			top: 50%;
			left: 50%;
			width: 40px;
			height: 40px;
			margin-top: -40px;
			margin-left: -20px;
			border-radius: 50%;
			border: 5px solid rgba(240, 240, 240, 0.8);
			border-top-color: rgba(0, 0, 0, 0.6);
			animation: ${spinner} 0.6s linear infinite;
			z-index: 2;
		}
	}
`;

/**
 * MapView is responsible for rendering the map correctly and setting the map object in the context.
 */
const MapView = ({ center }) => {
	const { mapObject, defaultProjection, dispatch } = useProject();
	const mapRef = useRef(null);
	const mapTooltipRef = useRef(null);

	const addingMap = useRef(false);

	useEffect(() => {
		if (center && !mapObject && !addingMap.current && mapRef.current) {
			addingMap.current = true;

			console.log('creating map');

			const map = new Map({
				target: mapRef.current,
				keyboardEventTarget: document,
				controls: [],
				loadTilesWhileAnimating: true,
				loadTilesWhileInteracting: true,
				interactions: defaultInteractions({
					altShiftDragRotate: false,
					pinchRotate: false,
					keyboardPan: true,
				}),
				renderer: 'webgl',
				view: new View({
					center: center,
					zoom: 17,
					projection: defaultProjection,
					minZoom: 6,
				}),
			});

			const mapLayer = new TileLayer({
				preload: Infinity,
				source: new OSM(),
				name: 'Map layer',
				zIndex: 1,
				properties: {
					customLayerId: 'mapTilesLayer',
				},
			});

			map.addLayer(mapLayer);
			map.getView().setCenter(center);

			const tooltip = createTooltip({
				mapRef: map,
				tooltipRef: mapTooltipRef.current,
				id: 'main-tooltip',
			});

			dispatch({ type: 'setMapObject', payload: map });
			dispatch({
				type: 'setMapTooltip',
				payload: { tooltip, tooltipRef: mapTooltipRef.current },
			});
		}

		return () => {
			if (mapObject) {
				mapObject.setTarget(null);
				mapObject.dispose();
				dispatch({ type: 'setMapObject', payload: null });
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [center]);

	return (
		<StyledMapView id="map" ref={mapRef}>
			<div ref={mapTooltipRef} className="ol-tooltip"></div>
		</StyledMapView>
	);
};

export default MapView;
