import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import Form from 'react-bootstrap/Form';
import Dropdown from 'react-bootstrap/Dropdown';

import NewModel from '../../utilityButtons/NewModel';
import Models from './Models';

import { useProject } from '@contexts/Project.context';
import { useAuth } from '@contexts/User.context';
import useSetSearchParams from '@hooks/useSetSearchParams.hook';
import { getAvailableModels, getDemoModel, getModelsDetectedOn } from '@api';
import CreateModelModal from '@components/modal/createModelModal/CreateModelModal';

const DropMenu = styled(Dropdown.Menu)`
	max-height: 55vh;
	min-width: 450px;
	overflow-y: overlay;
	padding-top: 0;
	padding-bottom: 0;
	--bs-dropdown-bg: ${props => props.theme.colors.background};
`;
const DropInner = styled.div`
	position: relative;
`;

const FilterLabel = styled.span`
	display: inline-block;
	color: ${props => props.theme.colors.textColor};
	opacity: 0.9;
	font-size: 1rem;
	font-weight: 600;
	text-transform: capitalize;
	position: relative;
	top: 7px;
	margin-right: 0.5rem;
	margin-bottom: 0.75rem;
`;

const TaskPicker = styled(Form)`
	display: flex;
	flex-direction: row;
	margin-right: 20px;
	flex-grow: 1;
	margin-top: 2px;
	align-items: center;
`;

const DropText = styled.i`
	opacity: 0.8;
	padding-right: 10px;
`;

/**
 * The toolbar in the map editor page.
 * It contains all functionality to interact with the map and the annotations on it on it.
 */
const ModelPicker = () => {
	const setSearchParams = useSetSearchParams();
	const [searchParams] = useSearchParams();

	const { currentUser, roleAdmin } = useAuth();

	const {
		project,
		pickedTask,
		tasks,
		toolBarVisible,
		isDemo,
		annotationMode,
		dispatch,
	} = useProject();

	const [showNewModelModal, setShowNewModelModal] = useState(false);

	const {
		data: tasksData,
		isLoading: tasksLoading,
		refetch: refetchTasks,
	} = useQuery({
		queryKey: ['tasks', currentUser?.uuid, currentUser?.active_org_id],
		queryFn: () => getAvailableModels(),
		enabled: !!currentUser?.uuid && !!project?.uuid,
		refetchOnWindowFocus: false,
	});

	const { data: modelsDetectedOn, isLoading: modelsDetectedOnLoading } =
		useQuery({
			queryKey: ['models_detected_on', project?.uuid],
			queryFn: () => getModelsDetectedOn(project?.uuid),
			enabled: !!project?.uuid,
			refetchOnWindowFocus: false,
		});

	const pickTask = task => {
		if (task) {
			// Reset the state
			dispatch({ type: 'setPickedModelClasses', payload: null });

			dispatch({ type: 'setFeatures', payload: [] });
			dispatch({
				type: 'setActiveSidebars',
				payload: [{ sidebarId: 'layerView' }],
			});
			dispatch({ type: 'setExportData', payload: null });

			// Set the task
			dispatch({ type: 'setPickedTask', payload: task });
			setSearchParams({ model: task.model_uuid });

			const taskType = task.task_type || task.classes[0].task_type;

			if (taskType) {
				dispatch({
					type: 'setModelType',
					payload: taskType,
				});
			}
		}
	};

	useEffect(() => {
		if (tasksLoading || modelsDetectedOnLoading) return;

		if (tasksData?.length > 0) {
			// Add task_type to each task object because it is not present in the API response
			// We need it like this for now
			const updatedTasks = tasksData.map(task => {
				const taskType = Array.isArray(task.classes)
					? task.classes[0].task_type
					: null;
				return {
					...task,
					task_type: taskType,
				};
			});

			dispatch({ type: 'setTasks', payload: updatedTasks });

			if (!pickedTask) {
				// If the model is in the URL, pick it
				const model = searchParams.get('model');
				const task = updatedTasks.find(
					task => task.model_uuid === model
				);

				if (task) {
					pickTask(task);
				} else if (
					(isDemo && project?.default_demo_model) ||
					(project?.default_demo_model && roleAdmin)
				) {
					// If it's a demo project, get and pick the default demo model
					const fetchDemoModel = async () => {
						try {
							const demoModel = await getDemoModel(
								project.default_demo_model
							);
							if (demoModel && demoModel.model_uuid) {
								pickTask(demoModel);
							}
						} catch (error) {
							console.error(
								'Error fetching default demo model',
								error
							);
						}
					};
					fetchDemoModel();
				}
			}
		} else {
			dispatch({ type: 'setTasks', payload: null });
		}
	}, [tasksData, modelsDetectedOn]);

	if (annotationMode) {
		return null;
	}
	return (
		<>
			<TaskPicker>
				<FilterLabel>Model:</FilterLabel>

				<Dropdown drop="up" id="modelDropDown">
					<Dropdown.Toggle
						variant="dark"
						drop="up"
						disabled={!toolBarVisible || !tasks || isDemo}>
						{!tasks ? (
							<DropText> Loading... </DropText>
						) : pickedTask ? (
							<DropText>{pickedTask.description}</DropText>
						) : (
							<DropText> Select a Model </DropText>
						)}
					</Dropdown.Toggle>

					<DropMenu variant="dark">
						<DropInner>
							<Dropdown.Item className="p-0 mb-2">
								<NewModel
									addCustomTask={() => {
										setShowNewModelModal(true);
									}}
									customModels={tasks?.filter(
										task => !task.basemodel
									)}
								/>
							</Dropdown.Item>

							<Models
								pickTask={pickTask}
								refetchTasks={refetchTasks}
								modelsDetectedOn={modelsDetectedOn}
							/>
						</DropInner>
					</DropMenu>
				</Dropdown>
			</TaskPicker>

			{showNewModelModal && (
				<CreateModelModal
					setShowModal={setShowNewModelModal}
					pickTask={pickTask}
				/>
			)}
		</>
	);
};
export default ModelPicker;
