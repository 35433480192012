import { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';

import { Map } from 'ol/index';
import { defaults as defaultInteractions } from 'ol/interaction';

import { StyledMapView } from '../Map';

const portalRoot = document.getElementById('portal-root');

/**
 * Creating a map for annotating single images
 */
const MapView = ({ map, setMap }) => {
	const mapRef = useRef(null);
	const addedMap = useRef(false);

	useEffect(() => {
		if (!addedMap.current && mapRef.current) {
			addedMap.current = true;

			console.log('creating map for annotating single images');

			const newMap = new Map({
				target: mapRef.current,
				controls: [],
				keyboardEventTarget: document,
				interactions: defaultInteractions({
					altShiftDragRotate: false,
					pinchRotate: false,
					keyboardPan: true,
				}),
				renderer: 'webgl',
			});

			setMap(newMap);
		}

		return () => {
			if (map) {
				console.log('disposing map for annotating single images');
				map.setTarget(null);
				map.dispose();
			}
		};
	}, [mapRef]);

	return (
		<>
			{createPortal(
				<StyledMapView id="single-image-map" ref={mapRef} />,
				portalRoot
			)}
		</>
	);
};

export default MapView;
