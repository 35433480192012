import pLimit from 'p-limit';
import { api, baseURL, Upload } from './index.js';

export const getProjects = async ({
	tags = null,
	offset = 0,
	limit = 100,
	search = '',
}) => {
	const params = {};

	if (tags?.length > 0) params.tags = tags.join(',');
	if (offset) params.offset = offset;
	if (search) params.search = search;
	params.limit = limit;

	const { data } = await api.get('/projects', { params });
	return data;
};

export const getDemoProjects = async () => {
	const { data } = await api.get('/projects/demo_projects');
	return data;
};

export const getProject = async project_id => {
	const { data } = await api.get(`/projects/${project_id}`);
	return data;
};

export const getAnnotations = async project_id => {
	try {
		const res = await api.get(`/project/${project_id}/annotations`);
		return res.data;
	} catch (err) {
		return err.response;
	}
};

export const getFeatures = async (projectId, taskName, jobId, feature_type) => {
	try {
		const res = await api.get(
			`/projects/${projectId}/${taskName}/${jobId}/features/${feature_type}`
		);
		return res.data;
	} catch (err) {
		return err.response;
	}
};

export const getMasterFeatures = async (projectId, taskId, feature_type) => {
	const { data } = await api.get(
		`/projects/${projectId}/${taskId}/features/${feature_type}`
	);
	return data;
};

export const deleteMasterFeatures = async (projectId, model_uuid) => {
	const { data } = await api.delete(
		`/projects/${projectId}/${model_uuid}/delete_master_features`
	);
	return data;
};

export const getMasterFeaturesTrainingData = async (
	projectId,
	taskId,
	feature_type
) => {
	const { data } = await api.get(
		`/projects/${projectId}/${taskId}/features/${feature_type}`
	);
	return data;
};

export const getHexagonFeatures = async (
	projectId,
	taskId,
	resolution,
	class_names = null
) => {
	const res = await api.post(
		`/projects/${projectId}/hexagons/${taskId}/${resolution}`,
		class_names
	);

	// The server returns 204 and text if there are no features.
	// I want to return null in that case.
	if (res.status === 204) return null;

	const { data } = res;

	return data;
};

export const bulkUploadImagesToS3 = async (
	files,
	project_id,
	setProgress,
	isOrthophoto
) => {
	try {
		if (!files || files.length === 0) throw new Error('No files');
		if (files.length === 1) {
			/**
			 * use new uploadclass to upload multipart here
			 */

			const upload = new Upload({
				fileRef: files[0],
				projectId: project_id,
				updateProgress: progress => setProgress(progress),
			});

			try {
				await upload.start();
			} catch (error) {
				console.error(error);
				throw error;
			}
		} else {
			try {
				// Using plimit is a temporary solution to use parallel uploads
				// It should be solved in the Upload class

				setProgress(1); // Set progress to 1% to show progress bar
				const maxConcurrentUploads = 5; // Maximum number of parallel uploads
				const limit = pLimit(maxConcurrentUploads);
				const uploadPromises = files.map((file, index) =>
					limit(async () => {
						const upload = new Upload({
							fileRef: file,
							projectId: project_id,
							updateProgress: _ =>
								setProgress(((index + 1) / files.length) * 100),
						});

						console.log('uploading: ' + file.name);
						await upload.start();
						files[index] = {
							name: file.name,
							size: file.size,
						};
					})
				);

				// Wait for all uploads to complete
				await Promise.all(uploadPromises);
			} catch (error) {
				console.error(error);
				throw error;
			}
		}
		return true;
	} catch (error) {
		console.error(error);
		throw error;
	}
};

export const getProjectProgress = async projectId => {
	const url = `/projects/${projectId}/progress`;
	try {
		const res = await api.get(url);
		return res.data;
	} catch (err) {
		return err.response;
	}
};

export const createProject = async data => {
	const res = await api.post('/projects', data);

	return res.data;
};

export const updateProject = async ({ uuid, title, description, tags }) => {
	const { data } = await api.post(`/projects/${uuid}/update`, {
		title,
		description,
		tags,
	});
	return data;
};

export const archiveProject = async project_uuid => {
	const { data } = await api.delete(`/projects/${project_uuid}`);
	return data;
};

export const addProjectTags = async (project_id, tags) => {
	const { data } = await api.post(`/projects/${project_id}/tags`, tags);
	return data;
};

export const deleteProjectTags = async (project_id, tags) => {
	const { data } = await api.delete(
		`${baseURL}/projects/${project_id}/tags`,
		{
			data: tags,
		}
	);
	return data;
};

export const getImageStatistics = async ({
	exifData,
	isOrthophoto,
	imageMode,
}) => {
	const urlPath = isOrthophoto ? 'tif' : 'png?image_mode=' + imageMode;
	const req_body = isOrthophoto ? exifData[0] : exifData;

	const { data } = await api.post(
		`/projects/exif/image_statistics/${urlPath}`,
		req_body
	);
	return data;
};

export const updateProjectWithS3Usage = async (project_id, is_orthophoto) => {
	const { data } = await api.post(`/projects/${project_id}/update_s3_usage`, {
		is_orthophoto,
	});

	return data;
};
