import { useEffect, useRef, useCallback, useState } from 'react';

import { useProject } from '@contexts/Project.context';
import Checkbox from '../sidebars/sidebarElements/Checkbox';
import { directoryExists, fileApiUrl } from '@utils/api';
import { makePhotoLayer } from '@utils/map/photo.layer';
import { getLayerByCustomId } from '@utils/map/helpers';

/*
 *  This component is responsible for rendering the photo layer and toggling its visibility.
 */
const PhotoLayer = ({
	name,
	layerId,
	directory,
	checkDirectory = true,
	maxZoomLevel,
	zIndex,
	visible = true,
}) => {
	const { mapObject, defaultProjection, project, extent } = useProject();

	const adding = useRef(false);
	const [photoLayer, setPhotoLayer] = useState(null);

	const updatePhotoLayer = useCallback(() => {
		if (!mapObject || !extent || adding.current) return null;

		adding.current = true;

		// Check if the layer already has been added to the map. It could be if this component is re-rendered.
		const existingLayer = getLayerByCustomId(mapObject, layerId);
		if (existingLayer) {
			console.log(`Layer ${name} already exists. Adding it to state.`);
			setPhotoLayer(existingLayer);
			adding.current = false;

			return;
		}

		const projectDirectory = project?.uuid + '/' + directory;
		const xyzUrl =
			fileApiUrl +
			'/filelink?key=' +
			projectDirectory +
			'/{z}/{x}/{-y}.png';
		checkDirectoryExists(projectDirectory, checkDirectory)
			.then(res => {
				if (!res) return;

				const newLayer = makePhotoLayer({
					name,
					zIndex,
					defaultProjection,
					url: xyzUrl,
					extent,
					maxZoomLevel,
					visible,
					layerId,
				});

				if (newLayer) {
					console.log(`adding ${name} layer`);
					mapObject.addLayer(newLayer);
				}

				setPhotoLayer(newLayer);
			})
			.catch(err => {
				console.error(err);
			})
			.finally(() => {
				adding.current = false;
			});
	}, [mapObject]);

	useEffect(() => {
		updatePhotoLayer();
	}, [updatePhotoLayer]);

	if (!photoLayer) return null;

	return (
		<div>
			<Checkbox
				label={photoLayer.get('name')}
				canEdit={false}
				layer={photoLayer}
				defaultState={photoLayer.getVisible()}
				handleCheck={() => photoLayer.setVisible(true)}
				handleUncheck={() => photoLayer.setVisible(false)}
			/>
		</div>
	);
};

export default PhotoLayer;

// This mainly exist because we can only rely on the Drone Photo to actually exist.
const checkDirectoryExists = (projectDirectory, checkDirectory) => {
	return new Promise((resolve, reject) => {
		// Checking directory takes time,
		// so if we don't need to check because we know it should exist,
		// we can resolve immediately
		if (!checkDirectory) {
			resolve(true);
		}

		directoryExists(projectDirectory)
			.then(res => {
				resolve(res);
			})
			.catch(err => {
				reject(err);
			});
	});
};
