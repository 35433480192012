import { useLocation } from 'react-router-dom';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';

import Navigation from './navigation/Navigation';
import Footer from './Footer';
import MaintenanceAlert from './MaintenanceAlert';

const Container = styled.div`
	background-color: ${props => props.theme.colors.background};
	min-height: ${props => props.theme.utils.fullHeight};
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`;

const Root = () => {
	const location = useLocation();
	const showFooter = !location.pathname.includes('projects'); // Hide footer on project pages to keep focus on the project

	return (
		<Container>
			<Navigation />
			<main>
				<MaintenanceAlert />
				<Outlet />
			</main>

			{showFooter && <Footer />}
		</Container>
	);
};
export default Root;
