import { forwardRef, useState } from 'react';
import styled from 'styled-components';

import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import AsyncSelect from 'react-select/async';

import HelperText from './HelperText';

const StyledSelectWrapper = styled.div`
	margin-bottom: ${props => (props.$noWrap ? '0' : '1rem')};
	position: relative;
	z-index: ${props => (props.$selectFocused ? '100' : '0')};
	.react-select {
		&__input-container {
			color: #fff;
			line-height: 1.4;
			margin-bottom: 0;
			padding-bottom: 0;
		}
		&__control {
			background-color: ${props => props.theme.colors.backgroundInput};
			border: 1px solid ${props => props.theme.colors.borderInput};
			color: #fff;
			padding: 1.25rem 0.25rem 0;

			&--is-focused {
				outline: 0;
				box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
			}
			&:hover {
				border-color: ${props => props.theme.colors.borderInput};
			}
		}
		&__indicator-separator {
			background-color: hsl(0, 0%, 40%);
		}
		&__menu {
			background-color: ${props => props.theme.colors.modal};
			color: #fff;
			border-top: 0;
		}
		&__option--is-focused {
			background-color: rgba(255, 255, 255, 0.1);
		}
		&__option--is-selected {
			background-color: ${props => props.theme.colors.green};
		}
		&__single-value {
			color: #fff;
		}
		&__multi-value {
			background-color: ${props => props.theme.colors.green};
			color: #fff;
		}
		&__value-container--is-multi {
			align-items: flex-end;
			padding-bottom: 4px;
		}
		&__multi-value__label {
			color: #fff;
			padding: 0 3px 0 6px;
			line-height: 1.4;
		}
		&__multi-value__remove {
			color: #fff;

			&:hover {
				background-color: #fff;
				color: ${props => props.theme.colors.modal};
			}
		}
	}
`;

const FloatingLabel = styled.label`
	position: absolute;
	top: 0;
	left: 0;
	z-index: 2;
	height: 100%;
	padding: 1rem 0.75rem;
	overflow: hidden;
	text-align: start;
	text-overflow: ellipsis;
	white-space: nowrap;
	pointer-events: none;
	border: var(--bs-border-width) solid transparent;
	transform-origin: 0 0;
	transition:
		opacity 0.1s ease-in-out,
		transform 0.1s ease-in-out;
	color: rgba(var(--bs-body-color-rgb), 0.65);
	transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
	&::after {
		position: absolute;
		inset: 1rem 0.375rem;
		z-index: -1;
		height: 1.5em;
		content: '';
		background-color: var(--bs-body-bg);
		border-radius: var(--bs-border-radius);
	}
`;

const AdvancedSelect = forwardRef(
	(
		{
			creatable = false,
			async = false,
			label = null,
			id = null,
			helperText = null,
			noWrap,
			...props
		},
		ref
	) => {
		const [selectFocused, setSelectFocused] = useState(false); // Used to fix issue with z-index and overlapping elements

		const commonProps = {
			id,
			className: 'react-select-container',
			classNamePrefix: 'react-select',
			onMenuOpen: () => setSelectFocused(true),
			onMenuClose: () => setSelectFocused(false),
			...props,
		};

		const renderSelectComponent = () => {
			const selectType = creatable
				? 'creatable'
				: async
					? 'async'
					: 'default';
			switch (selectType) {
				case 'creatable':
					return <CreatableSelect ref={ref} {...commonProps} />;
				case 'async':
					return <AsyncSelect ref={ref} {...commonProps} />;
				case 'default':
				default:
					return <Select ref={ref} {...commonProps} />;
			}
		};

		if (label && !id) {
			console.warn(
				'AdvancedSelect: id should be defined if label is defined'
			);
		}

		return (
			<StyledSelectWrapper
				$noWrap={noWrap}
				$selectFocused={selectFocused}>
				{label && <FloatingLabel htmlFor={id}>{label}</FloatingLabel>}

				{renderSelectComponent()}

				{helperText && id && (
					<HelperText id={`help-${id}`}>{helperText}</HelperText>
				)}
			</StyledSelectWrapper>
		);
	}
);

export default AdvancedSelect;
