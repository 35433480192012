import { useCallback, useRef, useState } from 'react';
import { RgbaColorPicker } from 'react-colorful';
import styled, { css } from 'styled-components';

import useClickAway from '@hooks/useClickAway';

const PickerButton = styled.button`
	border: none;
	border-radius: 5px;
	width: 45px;
	height: 28px;
	cursor: pointer;
	border: 1px solid #fff;
	background-color: #fff;
	background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill-opacity='.05'%3E%3Cpath d='M8 0h8v8H8zM0 8h8v8H0z'/%3E%3C/svg%3E");
	position: relative;

	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: ${({ color }) => color};
		border-radius: 5px;
	}
`;
const PickerButtonWrapper = styled.div`
	display: flex;
	flex-direction: ${p => (p.$stackItems ? 'column' : 'row')};
	gap: ${p => (p.$stackItems ? '5px' : '15px')};
`;

const Popover = styled.div`
	position: absolute;
	right: ${p => (p.$popdirection === 'right' ? 0 : 'auto')};
	transform: translateY(4px);
	z-index: 99;
	background: #fff;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
	border-radius: 4px;
	padding: 0;

	${p =>
		p.$hideAlpha &&
		css`
			.react-colorful {
				&__alpha {
					display: none;
				}
			}
		`};
`;
const PopoverPicker = ({
	label = 'Color',
	color,
	onChange,
	popDirection = 'left',
	hideAlpha = false,
	stackItems = true,
}) => {
	const popover = useRef();
	const [isOpen, toggle] = useState(false);

	const close = useCallback(() => toggle(false), []);
	useClickAway(popover, close);

	return (
		<div>
			<PickerButtonWrapper $stackItems={stackItems}>
				<label htmlFor="picker-button">{label}</label>
				<PickerButton
					id="picker-button"
					onClick={() => toggle(true)}
					aria-label="Toggle color picker"
					color={`rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`}
				/>
			</PickerButtonWrapper>

			{isOpen && (
				<Popover
					ref={popover}
					$popdirection={popDirection}
					$hideAlpha={hideAlpha}>
					<RgbaColorPicker color={color} onChange={onChange} />
				</Popover>
			)}
		</div>
	);
};
export default PopoverPicker;
